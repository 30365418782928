<template>
  <div>
    <v-data-table
      :headers="headers"
      :page="page"
      :items="items"
      :server-items-length="totalItems"
      :loading="isLoading"
      hide-default-footer
      :disable-sort="true"
      class="nowrap"
      @page-count="totalPageNum = $event"
    >
      <template v-slot:top>
        <v-container class="custom-container pa-6 mx-0">
          <v-row
            class="align-center mb-4"
          >
            <v-col
              :xl="filters.length > 0 ? 4 : 5"
              :lg="filters.length > 0 ? 4 : 5"
              :md="filters.length > 0 ? 4 : 5"
              sm="12"
              cols="12"
            >
              <h2>
                {{ title }}
              </h2>
              <small style="white-space: normal;">
                {{ subtitle }}
              </small>
            </v-col>
            <v-col
              :xl="filters.length > 0 ? 8 : 7"
              :lg="filters.length > 0 ? 8 : 7"
              :md="filters.length > 0 ? 8 : 7"
              sm="12"
              cols="12"
            >
              <v-row>
                <template v-if="filterLibrary === true">
                  <v-col
                    xl="5"
                    lg="5"
                    md="5"
                    cols="12"
                    class="offset-md-2"
                  >
                    <v-menu
                      v-model="date"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="to_date_atd"
                          label="Tanggal Pembelian"
                          class="filter"
                          readonly
                          outlined
                          dense
                          :append-icon="icons.mdiCalendar"
                          v-bind="attrs"
                          clearable
                          v-on="on"
                          @click="autofillToDateAtd"
                          @change="handleFilter($event, 'filter-purchase')"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="to_date_atd"
                        :first-day-of-week="0"
                        locale="id-id"
                        color="primary"
                        @change="handleFilter($event, 'filter-purchase')"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    xl="5"
                    lg="5"
                    md="5"
                    cols="12"
                  >
                    <v-autocomplete
                      label="Tipe Buku"
                      class="filter"
                      :items="libraryTypes"
                      item-text="name"
                      item-value="name"
                      outlined
                      clearable
                      dense
                    ></v-autocomplete>
                  </v-col>
                </template>
                <template v-if="noAddButton === true">
                  <template v-if="printRecapButton">
                    <v-col
                      :xl="filters.length > 0 ? 6 : 7"
                      :lg="filters.length > 0 ? 6 : 7"
                      :md="filters.length > 0 ? 6 : 7"
                      :cols="filters.length > 0 ? 12 : 12"
                    >
                      <v-text-field
                        v-model="search"
                        :append-icon="icons.mdiMagnify"
                        label="Search"
                        single-line
                        hide-details
                        dense
                        outlined
                        class=""
                      ></v-text-field>
                    </v-col>
                  </template>
                  <template v-if="printRecapWithNoSearch === true">
                    <v-col
                      md="5"
                      cols="12"
                    >
                      <v-btn
                        color="primary"
                        outlined
                        width="100%"
                        large
                        @click="$emit('print-recap')"
                      >
                        Cetak Rekapan
                      </v-btn>
                    </v-col>
                  </template>
                  <template v-else-if="printRecapButton">
                    <v-col
                      :xl="filters.length > 0 ? 6 : 7"
                      :lg="filters.length > 0 ? 6 : 7"
                      :md="filters.length > 0 ? 6 : 7"
                      :cols="filters.length > 0 ? 12 : 12"
                    >
                      <v-text-field
                        v-model="search"
                        :append-icon="icons.mdiMagnify"
                        label="Search"
                        single-line
                        hide-details
                        dense
                        outlined
                        class=""
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="5"
                      cols="12"
                    >
                      <v-btn
                        color="primary"
                        outlined
                        width="100%"
                        large
                        @click="$emit('print-recap')"
                      >
                        Cetak Rekapan
                      </v-btn>
                    </v-col>
                  </template>
                  <template v-else>
                    <template v-if="filterClassUpper === true">
                      <v-col
                        xl="5"
                        lg="5"
                        md="5"
                        cols="12"
                      >
                        <div>
                          <v-autocomplete
                            label="Kelas"
                            :items="classes"
                            item-text="name"
                            item-value="uuid"
                            outlined
                            clearable
                            :disabled="isLoading"
                            dense
                            class="filter"
                            @change="handleFilter($event, 'filter-classes')"
                          ></v-autocomplete>
                        </div>
                      </v-col>
                      <v-col
                        :xl="filters.length > 0 ? 6 : 7"
                        :lg="filters.length > 0 ? 6 : 7"
                        :md="filters.length > 0 ? 6 : 7"
                        :cols="filters.length > 0 ? 12 : 12"
                      >
                        <v-text-field
                          v-model="search"
                          :append-icon="icons.mdiMagnify"
                          label="Search"
                          single-line
                          hide-details
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                    </template>
                    <template
                      v-if="noSearch == false"
                    >
                      <v-col
                        :xl="filters.length > 0 ? 6 : 7"
                        :lg="filters.length > 0 ? 6 : 7"
                        :md="filters.length > 0 ? 6 : 7"
                        :cols="filters.length > 0 ? 12 : 12"
                        class="offset-md-5"
                      >
                        <v-text-field
                          v-model="search"
                          :append-icon="icons.mdiMagnify"
                          label="Search"
                          single-line
                          hide-details
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <v-col
                    :xl="filters.length > 0 ? 6 : 7"
                    :lg="filters.length > 0 ? 6 : 7"
                    :md="filters.length > 0 ? 6 : 7"
                    :cols="filters.length > 0 ? 12 : 12"
                  >
                    <v-text-field
                      v-model="search"
                      :append-icon="icons.mdiMagnify"
                      label="Search"
                      single-line
                      hide-details
                      dense
                      outlined
                      class=""
                    ></v-text-field>
                  </v-col>
                  <template v-if="filters.length > 0">
                    <v-col
                      xl="6"
                      lg="6"
                      md="6"
                      sm="12"
                      cols="12"
                    >
                      <v-row>
                        <v-col
                          cols="6"
                        >
                          <v-btn
                            color="primary"
                            large
                            width="100%"
                            @click="$emit('add-item')"
                          >
                            <v-icon>{{ icons.mdiPlus }}</v-icon> <span class="text-subtitle-2">{{ addBtn }}</span>
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="6"
                        >
                          <v-btn
                            color="primary"
                            width="100%"
                            large
                            @click="showFilter = !showFilter"
                          >
                            <v-icon>{{ icons.mdiFilter }}</v-icon> <span class="text-subtitle-2">{{ !showFilter ? 'Show filter' : 'Hide Filter' }}</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </template>
                  <template v-else>
                    <v-col
                      md="5"
                      cols="12"
                    >
                      <v-btn
                        color="primary"
                        width="100%"
                        large
                        @click="$emit('add-item')"
                      >
                        <v-icon>
                          {{ icons.mdiPlus }}
                        </v-icon> {{ addBtn }}
                      </v-btn>
                    </v-col>
                  </template>
                </template>
              </v-row>
            </v-col>
          </v-row>
          <template v-if="filterStudentAttendance === true">
            <v-row class="mb-1">
              <v-col
                xl="3"
                lg="3"
                md="3"
                sm="6"
                cols="12"
              >
                <div>
                  <v-text-field
                    v-model="search"
                    :append-icon="icons.mdiMagnify"
                    label="Search"
                    single-line
                    hide-details
                    dense
                    outlined
                  ></v-text-field>
                </div>
              </v-col>
              <v-col
                xl="3"
                lg="3"
                md="3"
                sm="6"
                cols="12"
              >
                <div>
                  <v-autocomplete
                    label="Pilih Kelas"
                    :items="classes"
                    item-text="name"
                    item-value="uuid"
                    outlined
                    dense
                    class="filter"
                    @change="handleFilter($event, 'filter-classes')"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col
                xl="3"
                lg="3"
                md="3"
                sm="6"
                cols="12"
              >
                <div>
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="from_date_atd"
                        label="Tanggal Mulai"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        @click="autofillFromDateAtd"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="from_date_atd"
                      :first-day-of-week="0"
                      locale="id-id"
                      color="primary"
                      @change="handleFilter($event, 'filter-from-date-atd')"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col
                xl="3"
                lg="3"
                md="3"
                sm="6"
                cols="12"
              >
                <div>
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="to_date_atd"
                        label="Tanggal Mulai"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        @click="autofillToDateAtd"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="to_date_atd"
                      :first-day-of-week="0"
                      locale="id-id"
                      color="primary"
                      @change="handleFilter($event, 'filter-to-date-atd')"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-if="filterClass === true">
            <v-row>
              <v-col cols="3">
                <div>
                  <v-autocomplete
                    label="Kelas"
                    :items="classes"
                    item-text="name"
                    item-value="uuid"
                    outlined
                    clearable
                    dense
                    class="filter"
                    @change="handleFilter($event, 'filter-classes')"
                  ></v-autocomplete>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-if="filterRecapVisitor === true">
            <v-row class="my-4">
              <v-col
                xl="4"
                lg="4"
                md="4"
                sm="6"
                cols="12"
                class="py-0"
              >
                <div>
                  <v-menu
                    v-model="start_date"
                    :close-on-content-click="true"
                    nudge-right="0"
                    nudge-top="20"
                    max-width="100%"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="start_date"
                        label="Tanggal Mulai"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        @click="autofillStartDate"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="start_date"
                      :first-day-of-week="0"
                      locale="id-id"
                      color="primary"
                      @input="start_date = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col
                xl="4"
                lg="4"
                md="4"
                sm="6"
                cols="12"
                class="py-0"
              >
                <!-- <div>
                  <v-menu
                    v-model="end_date"
                    :close-on-content-click="true"
                    nudge-right="0"
                    nudge-top="20"
                    max-width="100%"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="recap.end_date"
                        label="Tanggal Berakhir"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        @click="autofillEndDate"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="recap.end_date"
                      :first-day-of-week="0"
                      locale="id-id"
                      color="primary"
                      @input="end_date = false"
                    ></v-date-picker>
                  </v-menu>
                </div> -->
              </v-col>
              <v-col
                xl="4"
                lg="4"
                md="4"
                sm="6"
                cols="12"
                class="py-0"
              >
                <div>
                  <v-btn
                    color="primary"
                    dense
                    large
                    width="100%"
                  >
                    Tampilkan
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </template>
          <div class="d-md-flex">
            <div class="mx-0 mb-4 mb-md-0">
              <template v-if="filterStatus === true">
                <div>
                  <v-autocomplete
                    label="Status"
                    :items="status"
                    item-text="name"
                    item-value="id"
                    outlined
                    clearable
                    dense
                    class="filter"
                    @change="handleFilter($event, 'filter-status')"
                  ></v-autocomplete>
                </div>
              </template>
            </div>
            <div>
              <template v-if="importData">
                <div>
                  <button
                    class="mr-2 text-decoration v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default success"
                    @click="$emit('import-item')"
                  >
                    <v-icon
                      small
                      color="white"
                    >
                      {{ icons.mdiFileImportOutline }}
                    </v-icon> <span class="text-subtitle-2 d-block ml-1">IMPORT DATA</span>
                  </button>
                </div>
              </template>
            </div>
            <div>
              <template v-if="exportData">
                <div>
                  <a
                    class="text-decoration v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default info"
                    download
                    :href="exportData"
                  >
                    <v-icon
                      small
                      color="white"
                    >
                      {{ icons.mdiDownloadBoxOutline }}
                    </v-icon> <span class="text-subtitle-2 d-block ml-1">Download Template</span>
                  </a>
                </div>
              </template>
            </div>
          </div>
          <template v-if="packageFilter === true">
            <v-row>
              <v-col
                md="3"
                cols="12"
                class="py-0"
              >
                <div>
                  <v-autocomplete
                    label="Jenjang"
                    outlined
                    :items="level"
                    item-text="name"
                    item-value="name"
                    dense
                    @change="handleFilter($event, 'filter-level')"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col
                md="3"
                cols="12"
                class="py-0"
              >
                <div>
                  <v-autocomplete
                    label="Kecamatan"
                    :items="district"
                    :loading="district.length < 1"
                    item-text="name"
                    item-value="name"
                    dense
                    outlined
                    @change="handleFilter($event, 'filter-district')"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col
                md="3"
                cols="12"
                class="py-0"
              >
                <div>
                  <v-autocomplete
                    label="Kabupaten/Kota"
                    :items="regencies"
                    item-text="name"
                    item-value="name"
                    dense
                    outlined
                    @change="handleFilter($event, 'filter-regencies')"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col
                md="3"
                cols="12"
                class="py-0"
              >
                <div>
                  <v-autocomplete
                    label="Provinsi"
                    :items="province"
                    :loading="province.length < 1"
                    item-text="name"
                    item-value="name"
                    dense
                    outlined
                    @change="handleFilter($event, 'filter-province')"
                  ></v-autocomplete>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-if="teacherAccess === true">
            <v-row>
              <slot name="body"></slot>
            </v-row>
          </template>
          <v-expand-transition>
            <v-layout
              v-if="showFilter"
              row
            >
              <v-col
                cols="12"
              >
                <v-layout row>
                  <v-flex
                    v-for="filter in filters"
                    :key="filter.label"
                    class="mx-3"
                    md2
                  >
                    <v-autocomplete
                      v-if="filterReturnObject && !onReset"
                      :label="filter.label"
                      :items="filter.data"
                      :disabled="filter.data.length < 1"
                      return-object
                      item-text="name"
                      item-value="uuid"
                      dense
                      outlined
                      @change="handleFilter($event, filter.emitName)"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                      v-else-if="!filterReturnObject && !onReset"
                      :label="filter.label"
                      :items="filter.data"
                      :disabled="filter.data.length < 1"
                      dense
                      outlined
                      @change="handleFilter($event, filter.emitName)"
                    >
                    </v-autocomplete>
                    <v-skeleton-loader
                      v-else-if="onReset"
                      type="text"
                      class="mt-3 mb-3"
                    ></v-skeleton-loader>
                  </v-flex>
                  <!-- <div
                    class="ml-auto mr-0"
                  >
                    <v-btn
                      outlined
                      color="primary"
                      class="mr-4 ml-4"
                      @click="handleReset"
                    >
                      <v-icon>{{ icons.mdiAutorenew }}</v-icon> Reset filter
                    </v-btn>
                  </div> -->
                </v-layout>
              </v-col>
              <v-col
                md="2"
                class="pt-md-0"
                cols="12"
              >
                <v-btn
                  outlined
                  color="primary"
                  @click="handleReset"
                >
                  <v-icon>{{ icons.mdiAutorenew }}</v-icon> Reset filter
                </v-btn>
              </v-col>
            </v-layout>
          </v-expand-transition>
        </v-container>
      </template>
      <template v-slot:item.description="{ item }">
        <p>{{ (item.description || '').length > 19 ? item.description.substr(0,20) + '...' : item.description }}</p>
        <!-- <p>{{ item.description }}</p> -->
      </template>
      <template v-slot:item.description_fund="{ item }">
        <v-chip
          outlined
          class="v-chip-light-bg success--text"
          h-e-a-d
          @click="$emit('check-description',item.uuid)"
        >
          Lihat Deskripsi
        </v-chip>
        <!-- <p>{{ item.description }}</p> -->
      </template>
      <template v-slot:item.news_content="{ item }">
        <v-chip
          outlined
          class="v-chip-light-bg success--text"
          h-e-a-d
          @click="$emit('check-description',item.uuid)"
        >
          Lihat Deskripsi
        </v-chip>
        <!-- <p>{{ item.description }}</p> -->
      </template>
      <template v-slot:item.faq_content="{ item }">
        <v-chip
          outlined
          class="v-chip-light-bg success--text"
          h-e-a-d
          @click="$emit('check-description',item.uuid)"
        >
          Lihat Deskripsi
        </v-chip>
        <!-- <p>{{ item.description }}</p> -->
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ dateFormat(item.created_at, ' ', false, true) }}
      </template>
      <template v-slot:item.price="{ item }">
        {{ priceFormat(item.price) }}
      </template>
      <template v-slot:item.total_budget="{ item }">
        {{ priceFormat(item.total_budget) }}
      </template>
      <template v-slot:item.total_spent="{ item }">
        {{ priceFormat(item.total_spent) }}
      </template>
      <template v-slot:item.attendanceActions="{ item }">
        <div class="text-center">
          <v-btn
            v-if="actionsRow.includes('print')"
            color="primary"
            width="100%"
            large
            @click="$emit('print-item',item.uuid)"
          ></v-btn>
        </div>
      </template>
      <template
        v-slot:item.actions="{ item }"
        class="d-flex"
      >
        <div class="d-flex">
          <a
            v-if="historyItem"
            class="mx-2"
            @click.prevent="$emit('history-item',item.uuid)"
          >
            <v-tooltip bottom>
              <template #activator="{on,attrs }">
                <div>
                  <v-img
                    width="20"
                    height="20"
                    src="../../assets/icons/clipboard-text.svg"
                    class="p-0"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <!-- <v-icon class="">{{ icons.mdiTrashCanOutline }}</v-icon> -->
                  </v-img>
                </div>
              </template>
              <span>Riwayat Pembelian</span>
            </v-tooltip>
          </a>
          <a
            v-if="actionsRow.includes('edit')"
            class="mx-2"
            @click="$emit('edit-item',item.uuid)"
          >
            <v-tooltip bottom>
              <template #activator="{on,attrs }">
                <div>
                  <v-img
                    v-bind="attrs"
                    width="20"
                    height="20"
                    src="../../assets/icons/edit.svg"
                    class="p-0"
                    v-on="on"
                  >
                  </v-img>
                </div>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </a>
          <a
            v-if="actionsRow.includes('delete')"
            class="mx-2"
            @click="$emit('delete-item',item.uuid)"
          >
            <v-tooltip bottom>
              <template #activator="{on,attrs }">
                <div>
                  <v-img
                    width="20"
                    height="20"
                    src="../../assets/icons/trash.svg"
                    class="p-0"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <!-- <v-icon class="">{{ icons.mdiTrashCanOutline }}</v-icon> -->
                  </v-img>
                </div>
              </template>
              <span>Hapus</span>
            </v-tooltip>
          </a>
          <a
            v-if="actionsRow.includes('detail')"
            class="mx-2"
            @click="$emit('detail-item', item.uuid)"
          >
            <v-tooltip bottom>
              <template #activator="{on,attrs}">
                <div>
                  <v-img
                    width="20"
                    height="20"
                    src="../../assets/icons/eye.svg"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-img>
                </div>
              </template>
              <span>Detail</span>
            </v-tooltip>
          </a>
          <a
            v-if="printQRCode"
            class="mx-2"
            @click.prevent="$emit('print-qr-code',item.uuid)"
          >CETAK QR CODE</a>
          <a
            v-if="instanceSpod"
            class="mx-2"
            @click.prevent="$emit('instance-spod',item.uuid)"
          >
            <span
              v-if="item.company_types_uuid.type != 'sekolah'"
            >KOORDINAT SPOD</span>
            <span
              v-else
            >CETAK QR CODE</span>
          </a>
          <a
            v-if="openDialog"
            class="mx-2"
            @click.prevent="$emit('open-dialog',item.uuid)"
          >BELANJA PAKET BUKU</a>
        </div>
      </template>
      <template v-slot:item.openDetail="{ item }">
        <a
          class="mx-2"
          @click.prevent="$emit('open-detail',item.uuid)"
        >LIHAT RINCIAN</a>
      </template>
      <!-- <template v-slot:item.print="{ item }">
        <v-btn
          color="primary"
          dense
          width="100%"
          @click.prevent="$emit('print',item.uuid)"
        >
          CETAK
        </v-btn>
      </template> -->
      <template v-slot:item.checkbox="{ item }">
        <v-checkbox
          v-model="item.checkbox"
        ></v-checkbox>
      </template>
      <template v-slot:item.avatar="{ item }">
        <v-avatar class="my-2 d-flex cursor-pointer">
          <v-img
            alt="thumbnail"
            class=""
            :src="item.thumbnail"
          ></v-img>
        </v-avatar>
      </template>
      <template v-slot:item.content="{ item }">
        {{ item.content.length > 19 ? item.content.substr(0,20) + '...' : item.content }}
      </template>
      <template v-slot:item.place.address="{ item }">
        {{ item.place == null ? '' : (item.place.adress && item.place.address == null ? '' : (item.place.address && item.place.address.length > 30 ? item.place.address.substr(0, 30) + '...' : item.place.address)) }}
      </template>
      <template v-slot:item.title="{ item }">
        {{ item.title.length > 24 ? item.title.substr(0,25) : item.title }}
      </template>
      <template v-slot:item.publish_at="{ item }">
        {{ item.publish_at.slice(0,16) }}
      </template>
      <template v-slot:item.file="{ item }">
        <v-img
          alt="thumbnail"
          class="my-2"
          width="50"
          height="50"
          :src="item.file"
        ></v-img>
      </template>
      <template v-slot:item.thumbnail="{ item }">
        <div class="text-center">
          <v-avatar class="my-2 d-flex cursor-pointer">
            <v-img
              v-if="actionsRow.includes('show')"
              alt="thumbnail"
              class=""
              :src="item.thumbnail"
              @click="$emit('show-image',item.uuid)"
            ></v-img>
          </v-avatar>
        </div>
      </template>
      <template v-slot:item.photo="{ item }">
        <div class="text-center">
          <v-avatar
            v-if="item.photo !== null"
            class="my-2 d-flex cursor-pointer"
          >
            <v-img
              v-if="actionsRow.includes('show')"
              alt="photo"
              class=""
              :src="item.photo"
              @click="$emit('show-image',item.uuid)"
            ></v-img>
          </v-avatar>
          <v-avatar
            v-else
            class="my-2 d-flex cursor-pointer"
          >
            <v-img
              v-if="actionsRow.includes('show')"
              alt="photo"
              class=""
              src="@/assets/images/avatars/ari.png"
              @click="$emit('show-image',item.uuid)"
            ></v-img>
          </v-avatar>
        </div>
      </template>
      <template v-slot:item.image="{ item }">
        <div class="text-center">
          <v-avatar
            v-if="item.image !== null"
            class="my-2 d-flex cursor-pointer"
          >
            <v-img
              v-if="actionsRow.includes('show')"
              alt="image"
              class=""
              :src="item.image"
              @click="$emit('show-image',item.uuid)"
            ></v-img>
          </v-avatar>
          <v-avatar
            v-else
            class="my-2 d-flex cursor-pointer"
          >
            <v-img
              v-if="actionsRow.includes('show')"
              alt="image"
              class=""
              src="@/assets/images/avatars/ari.png"
              @click="$emit('show-image',item.uuid)"
            ></v-img>
          </v-avatar>
        </div>
      </template>
      <template v-slot:item.icon="{ item }">
        <div class="text-center">
          <v-avatar class="my-2 d-flex">
            <v-img
              v-if="actionsRow.includes('show')"
              alt="icon"
              class=""
              :src="item.icon"
              @click="$emit('show-icon',item.uuid)"
            ></v-img>
          </v-avatar>
        </div>
      </template>
      <template v-slot:item.chip="{ item }">
        <v-chip
          :class="getColor(item.chip)"
          h-e-a-d
        >
          {{ item.chip }}
        </v-chip>
      </template>
      <template v-slot:item.detail="{ item }">
        <v-chip
          v-if="item ? true : false"
          color="success"
          class="v-chip-light-bg success--text"
          @click="$emit('show-detail',item.uuid)"
        >
          Lihat
        </v-chip>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          v-show="item.status"
          :class="getColorStatus(item.status)"
          h-e-a-d
        >
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:item.typeLibrary="{ item }">
        <v-chip
          v-for="(itemCL,index) in item.typeLibrary"
          :key="index"
          outlined
          :class="getBadgeType(itemCL)"
          h-e-a-d
        >
          <v-avatar left>
            <v-icon>{{ getIconType(index) }}</v-icon>
          </v-avatar>
          {{ getTextType(itemCL) }}
        </v-chip>
      </template>
      <template v-slot:item.categoryList="{ item }">
        <ul>
          <li
            v-for="(itemCL,index) in item.category"
            :key="index"
          >
            {{ itemCL.name }}
          </li>
        </ul>
      </template>
      <template v-slot:item.subcategoryList="{ item }">
        <ul>
          <li
            v-for="(itemCL,index) in item.subcategory"
            :key="index"
          >
            {{ itemCL.name }}
          </li>
        </ul>
      </template>
      <template v-slot:item.subsubcategoryList="{ item }">
        <ul>
          <li
            v-for="(itemCL,index) in item.subsubcategory"
            :key="index"
          >
            {{ itemCL.name }}
          </li>
        </ul>
      </template>
      <template v-slot:item.subsubsubcategoryList="{ item }">
        <ul>
          <li
            v-for="(itemCL,index) in item.subsubsubcategory"
            :key="index"
          >
            {{ itemCL.name }}
          </li>
        </ul>
      </template>
      <template v-slot:item.subsubsubsubcategoryList="{ item }">
        <ul>
          <li
            v-for="(itemCL,index) in item.subsubsubsubcategory"
            :key="index"
          >
            {{ itemCL.name }}
          </li>
        </ul>
      </template>
      <template v-slot:item.is_active="{ item }">
        <v-chip
          :class="getColorActive(item.is_active)"
          dark
        >
          {{ item.is_active }}
        </v-chip>
      </template>
      <template v-slot:item.employeeNameAndPhoto="{ item }">
        <div class="d-flex align-center cursor-pointer">
          <div class="mr-2">
            <v-avatar class="my-2 d-flex">
              <v-img
                v-if="actionsRow.includes('show')"
                alt="photo"
                class=""
                :src="item.photo"
                @click="$emit('show-image', item.uuid)"
              ></v-img>
            </v-avatar>
          </div>
          <span>{{ item.name }}</span>
        </div>
      </template>
      <template v-slot:item.candidateName="{ item }">
        <div>
          <template>
            <v-list>
              <v-list-item
                v-for="(itemCandidate, index) in item.candidate"
                :key="index"
                class="pa-0 d-flex justify-start align-center"
              >
                <v-list-item-icon class="mr-0">
                  <v-icon
                    x-small
                    class="d-block"
                    color="primary"
                    v-text="icons.mdiCheckboxBlankCircle"
                  >
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title class="py-1">
                  <p class="ma-0 text-body-2">
                    {{ itemCandidate.name }}
                  </p>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </template>
        </div>
      </template>
      <template v-slot:item.tagName="{ item }">
        <div>
          <template>
            <v-list>
              <v-list-item
                v-for="(itemTag, index) in item.tags"
                :key="index"
                class="pa-0 d-flex justify-start align-center"
              >
                <v-list-item-icon class="mr-0">
                  <v-icon
                    x-small
                    class="d-block"
                    color="primary"
                    v-text="icons.mdiCheckboxBlankCircle"
                  >
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title class="py-1">
                  <p class="ma-0 text-body-2">
                    {{ itemTag.name }}
                  </p>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </template>
          <!-- <v-chip
            class="v-chip-light-bg primary--text mr-2"
            color="primary"
          >
            Example {{ item.tags.name }}
          </v-chip> -->
        </div>
      </template>
      <template v-slot:item.button>
        <v-btn
          color="primary"
        >
          {{ actionBtn }}
        </v-btn>
      </template>
    </v-data-table>

    <div class="d-flex justify-space-between pt-2 pb-2">
      <div class="ml-4 mt-3">
        <h5>Total item: {{ totalItems }}</h5>
      </div>
      <v-pagination
        v-model="page"
        :length="totalPages"
        total-visible="7"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import toCurrencyFormat from '@/utils/currency/toCurrencyFormat'
import dateTimeFormat from '@/utils/date/dateTimeFormat'
// eslint-disable-next-line object-curly-newline
import { mdiAutorenew, mdiBook, mdiBookMusic, mdiCalendar, mdiCheckboxBlankCircle, mdiClose, mdiDownloadBoxOutline, mdiEyeOutline, mdiFileImportOutline, mdiFileVideo, mdiFilter, mdiMagnify, mdiPlus } from '@mdi/js'

export default {
  name: 'DataTablePagination',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    province: {
      type: Array,
      default: () => [],
    },
    regencies: {
      type: Array,
      default: () => [],
    },
    district: {
      type: Array,
      default: () => [],
    },
    level: {
      type: Array,
      default: () => [],
    },
    libraryTypes: {
      type: Array,
      default: () => [],
    },
    totalItems: {
      type: Number,
      default: () => 0,
    },
    title: {
      type: String,
      default: () => '',
    },
    subtitle: {
      type: String,
      default: () => '',
    },
    chip: {
      type: String,
      default: () => '',
    },
    detail: {
      type: String,
      default: () => '',
    },
    addBtn: {
      type: String,
      default: () => '',
    },
    actionBtn: {
      type: String,
      default: () => '',
    },
    filters: {
      type: Array,
      default: () => [],
    },
    noAddButton: {
      type: Boolean,
      default: () => false,
    },
    noSearch: {
      type: Boolean,
      default: () => false,
    },
    filterLibrary: {
      type: Boolean,
      default: () => false,
    },
    printRecapButton: {
      type: Boolean,
      default: () => false,
    },
    printRecapWithNoSearch: {
      type: Boolean,
      default: () => false,
    },
    filterClass: {
      type: Boolean,
      default: () => false,
    },
    filterRecapVisitor: {
      type: Boolean,
      default: () => false,
    },
    filterClassUpper: {
      type: Boolean,
      default: () => false,
    },
    filterStatus: {
      type: Boolean,
      default: () => false,
    },
    filterStudentAttendance: {
      type: Boolean,
      default: () => false,
    },
    classes: {
      type: Array,
      default: () => [],
    },
    status: {
      type: Array,
      default: () => [],
    },
    packageFilter: {
      type: Boolean,
      default: () => false,
    },
    importData: {
      type: Boolean,
      default: () => false,
    },
    exportData: {
      type: String,
      default: () => '',
    },
    teacherAccess: {
      type: Boolean,
      default: () => false,
    },
    filterReturnObject: {
      type: Boolean,
      default: () => false,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    isActive: {
      type: String,
      default: () => '',
    },
    totalPages: {
      type: Number,
      default: () => 0,
    },
    avatar: {
      type: String,
      default: () => '',
    },
    image: {
      type: String,
      default: () => '',
    },
    actionsRow: {
      type: Array,
      default: () => ['edit', 'delete', 'show', 'print'],
    },
  },
  data() {
    return {
      page: 1,
      search: '',
      checkbox: false,
      button: '',
      icons: {
        mdiFileImportOutline,
        mdiMagnify,
        mdiPlus,
        mdiFilter,
        mdiAutorenew,
        mdiEyeOutline,
        mdiClose,
        mdiCheckboxBlankCircle,
        mdiDownloadBoxOutline,
        mdiBook,
        mdiBookMusic,
        mdiFileVideo,
        mdiCalendar,
      },
      showFilter: false,
      onReset: false,
      totalPageData: this.totalPages,
      overlay: false,
      zIndex: 10,
      from_date_atd: '',
      to_date_atd: '',
      date: false,
    }
  },
  computed: {
    openDialog() {
      if (this.$listeners['open-dialog']) {
        return true
      }

      return false
    },
    printQRCode() {
      if (this.$listeners['print-qr-code']) {
        return true
      }

      return false
    },
    instanceSpod() {
      if (this.$listeners['instance-spod']) {
        return true
      }

      return false
    },
    historyItem() {
      if (this.$listeners['history-item']) {
        return true
      }

      return false
    },
    searchQuery: {
      get() {
        return this.search
      },
      set(val) {
        this.searchInput = val
      },
    },
    totalPageNum: {
      get() {
        return this.totalPageData
      },
      set(val) {
        this.totalPageData = val
      },
    },
  },
  watch: {
    page: {
      handler() {
        this.$emit('change-page', this.page)
      },
    },
    totalPages: {
      handler() {
        this.totalPageData = this.totalPages
      },
    },
    search: {
      handler() {
        if (this.search.length > 3 || this.search.length === 0) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.page = 1
            this.$emit('live-search', this.search)
          }, 500)
        }
      },
    },
  },
  mounted() {},
  methods: {
    handleFilter(event, emitName) {
      this.$emit(emitName, event)
    },
    autofillFromDateAtd() {
      this.from_date_atd = new Date().toISOString().substr(0, 10)
    },
    autofillToDateAtd() {
      this.to_date_atd = new Date().toISOString().substr(0, 10)
    },
    autofillStartDate() {
      this.library.date = new Date().toISOString().substr(0, 10)
    },
    priceFormat(val) {
      return `${toCurrencyFormat(val)}`
    },
    dateFormat(val) {
      return dateTimeFormat(val, ' ', false, true)
    },
    getColorStatus(status) {
      if (status === 'active') return 'v-chip-light-bg success--text'
      if (status === 'not active') return 'v-chip-light-bg success--text'
      if (status === 'Jangka waktu') return 'v-chip-light-bg warning--text'
      if (status === 'Permanen') return 'v-chip-light-bg warning--text'

      return 'v-chip-light-bg primary--text'
    },
    getBadgeType(type) {
      if (type === null || type === 'null' || type === '') {
        return 'v-chip-light-bg error--text'
      }

      return 'v-chip-light-bg success--text'
    },
    getTextType(type) {
      if (type === null || type === 'null' || type === '') {
        return '-'
      }

      return '+'
    },
    getIconType(icon) {
      if (icon === 'ebook') {
        return mdiBook
      }
      if (icon === 'audio') {
        return mdiBookMusic
      }

      return mdiFileVideo
    },
    getColor(isActive) {
      if (isActive === 'Active') return 'v-chip-light-bg success--text'
      if (isActive === 'Not Active') return 'v-chip-light-bg error--text'

      return 'v-chip-light-bg primary--text'
    },
    getColorActive(isActive) {
      if (isActive === 'Active') return 'v-chip-light-bg success--text'
      if (isActive === 'Not Active') return 'v-chip-light-bg error--text'

      return 'v-chip-light-bg primary--text'
    },
    handleReset() {
      this.onReset = true
      setTimeout(() => {
        this.onReset = false
      }, 300)
      this.$emit('reset-filter')
    },
    showOverlay(uuid) {
      this.overlay = !this.overlay
    },
  },
}
</script>

<style>
.nowrap {
  white-space: nowrap !important;
}
.close-image {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 11;
}
.v-image.v-responsive {
  border-radius: 6px;
}
.v-list-item__icon {
  align-self: center;
}
.filter > .v-input__control > .v-text-field__details {
  display: none;
}
.filter > .v-input__control > .v-input__slot {
  margin: 0 !important;
}
@media (min-width: 960px) {
  .custom-container {
    max-width: 100% !important;
  }
}
</style>
