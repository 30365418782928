<template>
  <div
    id="scrollbar"
    class="packet-option"
  >
    <v-container>
      <div
        v-if="!pkg"
        class="text-center"
      >
        <v-progress-circular
          color="primary"
          class="mx-auto mb-3"
          :size="40"
          indeterminate
        ></v-progress-circular>
      </div>
      <div
        v-else
        class="packet-header d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row px-1 py-4"
      >
        <div class="header-left d-flex">
          <ul>
            <li>Nama Paket</li>
            <li>Deskripsi</li>
          </ul>
          <ul class="ml-4">
            <li class="font-weight-medium">
              : {{ pkg.name }}
            </li>
            <li class="font-weight-medium">
              : {{ pkg.description }}
            </li>
          </ul>
        </div>
        <div class="header-right d-flex ml-sm-10 ml-md-10 ml-lg-10 ml-xl-10">
          <ul class="ml-0 pl-0">
            <li>Harga</li>
            <li v-if="dialog ==='edit'">
              Jumlah Buku
            </li>
          </ul>
          <ul class="ml-4">
            <li class="font-weight-medium">
              : {{ priceFormat(pkg.price) }}
            </li>
            <li
              v-if="dialog === 'edit'"
              class="font-weight-medium"
            >
              : {{ pkg.total_library }}
            </li>
          </ul>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="packet-title d-flex align-center justify-space-between px-1 mt-4">
        <v-col
          cols="6"
          class="pl-0"
        >
          <h1>Pilih Buku</h1>
          <p>Cari dan pilih buku sesuai kebutuhan paket</p>

          <div class="d-flex align-center">
            Pilih semua berdasarkan kategori : <v-switch
              v-model="isSelectAll"
              class="ml-4"
              @change="checkButton"
            ></v-switch>
          </div>
        </v-col>
        <v-col
          cols="6"
          class="pr-0"
        >
          <v-text-field
            v-model="search"
            label="Search"
            single-line
            hide-details
            dense
            outlined
            class=""
          ></v-text-field>
        </v-col>
        <div>
        </div>
      </div>
      <v-row class="px-1">
        <v-col
          md="5"
          cols="12"
        >
          <v-card outlined>
            <v-card-title>
              <h4 class="font-weight-medium">
                Kategori Buku
              </h4>
            </v-card-title>
            <v-divider></v-divider>
            <v-progress-circular
              v-if="!categories.length"
              color="primary"
              :size="45"
              indeterminate
              class="position-absolute"
              style="top:60%;left:50%;transform:translate(-50%,-50%);z-index:1"
            ></v-progress-circular>
            <v-list
              height="400"
              class="overflow-auto"
              rounded
            >
              <v-list-item-group>
                <v-list-item
                  class="pl-2"
                  @click="clickAll"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      Semua Kategori
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item-title>
                  <v-treeview
                    activatable
                    rounded
                    hoverable
                    color="primary"
                    :items="categories"
                    item-key="uuid"
                    item-text="name"
                    item-children="sub_categories"
                  >
                    <template
                      slot="label"
                      slot-scope="{ item }"
                    >
                      <div :class="isLoadingLibrary ? 'disable-wrap' : ''">
                        <a
                          class="d-flex justify-space-between align-center"
                          :class="isLoadingLibrary ? 'disable' : ''"
                          @click.prevent="changeLibraryList(item.uuid)"
                        >
                          <span
                            class="text-truncate"
                            style="max-width: 150px"
                          >{{ item.name }}</span>
                          <v-chip
                            color="primary"
                          >
                            {{ item.total_libraries }} Buku
                          </v-chip>
                        </a>
                      </div>
                    </template>
                  </v-treeview>
                </v-list-item-title>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
        <v-col
          md="7"
          cols="12"
        >
          <v-card outlined>
            <v-card-title class="d-flex justify-space-between">
              <h4 class="font-weight-medium ">
                Daftar Buku
              </h4>
            </v-card-title>
            <v-divider></v-divider>
            <v-simple-table
              class="overflow-auto"
            >
              <thead style="border-top: none">
                <tr>
                  <th class="text-left">
                    <!-- TODO: Temporary disable for future development -->
                    <!-- <v-checkbox
                      v-model="allSelected"
                      @change="selectAll"
                    ></v-checkbox> -->
                  </th>
                  <th class="text-left">
                    No
                  </th>
                  <th class="text-left">
                    Nama Buku
                  </th>
                  <th class="text-left">
                    Jumlah Salinan
                  </th>
                </tr>
              </thead>
            </v-simple-table>
            <v-simple-table
              id="scrollbar2"
              height="346"
              class="overflow-auto"
            >
              <template
                v-slot:default
              >
                <tbody>
                  <tr
                    v-if="isLoadingLibrary"
                    style="display: contents"
                  >
                    <td
                      colspan="3"
                      style="border: none"
                    >
                      <div
                        class="d-block text-center mx-auto mt-5"
                      >
                        <v-progress-circular
                          class="mt-5"
                          color="primary"
                          :size="50"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-for="(library, index) in libraries"
                    v-else
                    :key="library.uuid"
                  >
                    <td>
                      <v-checkbox
                        v-model="librariesToAdd[library.uuid]"
                        :value="library.uuid"
                        uuid
                        @change="handleCheckboxChange(library.uuid, librariesToAdd)"
                      >
                      </v-checkbox>
                    </td>
                    <td>{{ index+1 }}</td>
                    <td>{{ library.name }}</td>
                    <td>
                      <v-select
                        v-if="librariesToAdd[library.uuid] && library.type == 'ebook'"
                        v-model="quantityToAdd[library.uuid]"
                        :items="totalCopies"
                        item-text="label"
                        hide-details="none"
                        outlined
                        return
                        dense
                        label="Jumlah Salinan"
                        :value="5"
                        @change="handleSelectChange($event, quantityToAdd)"
                      >
                      </v-select>
                    </td>
                    <!-- <td>{{ library.name }}</td>
                    <td>
                      <v-autocomplete
                        v-model="quantity[library.uuid]"
                        :items="itemQuantity"
                        class="mt-5"
                        dense
                        :value="itemQuantity"
                        outlined
                        @change="handleAcChange(quantity, quantityToAdd)"
                      ></v-autocomplete>
                    </td> -->
                  </tr>
                  <infinite-loading
                    v-if="!categoryUuid"
                    :identifier="infiniteId"
                    @distance="1"
                    @infinite="infiniteHandler"
                  >
                    <div slot="no-more">
                    </div>
                    <div slot="no-results">
                    </div>
                  </infinite-loading>
                  <infinite-loading
                    v-else
                    :identifier="infiniteId"
                    @distance="1"
                    @infinite="infiniteHandlerCategory"
                  >
                    <div slot="no-more">
                    </div>
                    <div slot="no-results">
                    </div>
                  </infinite-loading>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="px-1 pb-5">
        <v-col
          md="6"
          cols="12"
        >
          <v-card class="">
            <v-card-text>
              <h4>Buku yang dipilih : {{ Object.keys(librariesToAdd).length ? Object.keys(librariesToAdd).length : 0 }} dari {{ totalBook }}</h4>
              <p>Kamu Telah memilih buku sesuai jumlah buku dari paket</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <div class="notice">
            <h3>Keterangan :</h3>
            <div class="d-flex justify-space-between">
              <div
                v-for="(notice) in notices"
                :key="notice.title"
              >
                <div class="d-flex align-center">
                  <v-checkbox
                    v-model="notice.isChecked"
                    :disabled="notice.disable"
                    :class="(notice.isColored === true) ? 'is-colored' : ''"
                  ></v-checkbox>
                  <small>{{ notice.title }}</small>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-center mb-1">
        <v-btn
          color="primary"
          class="mx-2"
          large
          :disabled="!Object.keys(librariesToAdd).length"
          :loading="isLoadingButton"
          @click="dialog ==='edit' ? update() : save()"
        >
          Simpan
        </v-btn>
        <v-btn
          color="primary"
          outlined
          class="mx-2"
          large
          @click="$emit('prev-tab')"
        >
          Sebelumnya
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import currencyToIntFormat from '@/utils/currency/currencyToIntFormat'
import toCurrencyFormat from '@/utils/currency/toCurrencyFormat'
import { mdiBook, mdiBookMusic, mdiFileVideo } from '@mdi/js'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  components: {
    InfiniteLoading,
  },
  props: {
    pkg: {
      type: Object,
      default: () => null,
    },
    dialog: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      isInfinite: true,
      isSelectAll: false,
      selected: '',
      icons: {
        mdiBook,
        mdiBookMusic,
        mdiFileVideo,
      },
      packetOptionDialog: false,
      categories: [],
      libraries: [],
      librariesSelected: [],
      isLoadingLibrary: false,
      isLoadingCategory: false,
      isLoadingButton: false,
      isLoadingPacket: false,
      searchDebounce: '',
      timeout: null,
      packet: {
        uuid: null,
        name: '',
        description: '',
        level: '',
        total_library: 0,
        price: 0,
        library: [],
        quantity: [],
      },
      notices: [
        {
          title: 'Buku yang akan dibeli',
          disable: true,
          isChecked: true,
          isColored: true,
        },
        {
          title: 'Buku yang sudah dibeli',
          disable: true,
          isChecked: true,
          isColored: false,
        },
        {
          title: 'Buku yang tidak dibeli',
          disable: true,
          isChecked: false,
          isColored: false,
        },
      ],
      serviceCategory: 'category',
      serviceLibrary: 'library',
      serviceLibraryCategory: 'categorylibrary',
      servicePackageLibrary: 'packagelibrary',
      servicePackage: 'package',
      totalBook: 0,
      totalChecked: 0,
      librariesToAdd: [],
      quantity: [],
      quantityToAdd: [],
      librariesToRemove: [],
      totalItemsLib: 0,
      totalPagesLib: 100,
      totalCopies: [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
        { label: '8', value: 8 },
        { label: '9', value: 9 },
        { label: '10', value: 10 },
      ],
      pageLib: 1,
      pageLibCategory: 1,
      changeUuid: null,
      infiniteId: +new Date(),
      categoryUuid: null,
      itemQuantity: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      checkedBook: [],
      allSelected: false,
    }
  },
  computed: {
    price() {
      return toCurrencyFormat(this.packet.price)
    },
    search: {
      get() {
        return this.searchDebounce
      },
      set(val) {
        if (val.length > 3 || val.length === 0) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.searchDebounce = val
            this.pageLib = 1
            this.pageLibCategory = 1
            this.listLibrary({ library: this.searchDebounce })
            this.refreshInfiniteLoading()
          }, 500)
        }
      },
    },

  },
  watch: {
    pkg() {
      if (this.pkg.uuid.length && this.dialog === 'edit') {
        this.getSelectedLibraries()
      }
    },
  },
  created() {
    if (this.pkg.uuid.length && this.dialog === 'edit') {
      this.getSelectedLibraries()
    }
    this.listLibrary()
  },
  mounted() {
    this.listCategory()
  },
  methods: {
    refreshInfiniteLoading() {
      if (this.pageLib === 1 || this.pageLibCategory === 1) {
        this.infiniteId += 1
      }
    },

    priceFormat(value) {
      return toCurrencyFormat(value)
    },

    async infiniteHandler($state) {
      // console.log('oke 1')
      this.pageLib += 1
      await this.$services.ApiServices.list(this.serviceLibrary, {
        library: this.searchDebounce,
        per_page: 100,
        page: this.pageLib,
      }).then(({ data }) => {
        if (data.data.length) {
          this.libraries = [...this.libraries, ...data.data]
          this.libraries = this.libraries.map(item => ({
            ...item,
            typeLibrary: {
              audio: item.audio,
              video: item.video,
              ebook: item.ebook,
            },
          }))
          this.totalBook = data.meta.total

          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
    async infiniteHandlerCategory($state) {
      this.pageLibCategory += 1
      // console.log('oke2')
      await this.$services.ApiServices.get(this.serviceLibraryCategory, this.categoryUuid, {
        library: this.searchDebounce,
        page: this.pageLibCategory,
        per_page: this.isSelectAll ? 'all' : 100,
      }).then(({ data }) => {
        if (data.data.length) {
          this.libraries = [...this.libraries, ...data.data]
          this.libraries = this.libraries.map(item => ({
            ...item,
            typeLibrary: {
              audio: item.audio,
              video: item.video,
              ebook: item.ebook,
            },
          }))
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
    async save() {
      this.isLoadingButton = true
      this.pkg.total_book = this.librariesToAdd.length
      this.pkg.price = currencyToIntFormat(this.pkg.price)
      await this.$services.ApiServices.add(this.servicePackage, this.pkg).then(
        ({ data }) => {
          this.packet = data.data
        },
        err => console.error(err),
      )

      this.formData = new FormData()
      Object.entries(this.librariesToAdd).forEach(item => {
        console.log('item', item)
        this.formData.append('library_uuid[]', item[0])
        console.log('fd', this.formData.getAll('library_uuid[]'))
      })
      Object.entries(this.quantityToAdd).forEach(quantity => {
        // console.log('itemq', quantity[1])
        this.formData.append('quantity[]', quantity[1])
        // console.log('fd', this.formData.getAll('quantity[]'))
      })
      // this.formData.append('quantity', this.quantityToAdd)
      this.formData.append('package_uuid', this.packet.uuid)
      await this.$services.ApiServices.add(this.servicePackageLibrary, this.formData).then(
        res => null,
        err => console.error(err),
      )
      this.showSnackbar({
        text: 'Add Package Has Successfully',
        color: 'success',
      })
      this.isLoadingButton = false
      this.librariesToAdd = []
      this.quantityToAdd = []
      this.$emit('close-dialog')
      this.$emit('success-save')
    },

    async update() {
      this.isLoadingButton = true
      this.pkg.total_book = this.librariesToAdd.length
      this.pkg.price = currencyToIntFormat(this.pkg.price)
      await this.$services.ApiServices.update(this.servicePackage, this.pkg, this.pkg.uuid).then(
        ({ data }) => {
          this.packet = data.data
        },
        err => console.error(err),
      )

      this.formData = new FormData()
      Object.entries(this.librariesToAdd).forEach(item => {
        console.log('item', item)
        this.formData.append('library_uuid[]', item[0])
        console.log('fd', this.formData.getAll('library_uuid[]'))
      })
      Object.entries(this.quantityToAdd).forEach(quantity => {
        // console.log('itemq', quantity[1])
        this.formData.append('quantity[]', quantity[1])
        // console.log('fd', this.formData.getAll('quantity[]'))
      })
      // this.formData.append('quantity', this.quantityToAdd)
      this.formData.append('package_uuid', this.packet.uuid)
      await this.$services.ApiServices.add(this.servicePackageLibrary, this.formData).then(
        res => null,
        err => console.error(err),
      )
      this.showSnackbar({
        text: 'Update paket sukses',
        color: 'success',
      })
      this.isLoadingButton = false
      this.librariesToAdd = []
      this.quantityToAdd = []
      this.$emit('close-dialog')
      this.$emit('success-save')
    },

    async listCategory() {
      await this.$services.ApiServices.list(this.serviceCategory, { per_page: 'all' }).then(
        ({ data }) => {
          this.categories = data.data
        },
        err => console.error(err),
      )
    },
    async getSelectedLibraries() {
      this.librariesToAdd = []

      await this.$services.ApiServices.get(this.servicePackage, this.pkg.uuid).then(
        ({ data }) => {
          console.log('ini data', data)
          data.data.library.forEach(item => { this.librariesToAdd[item.uuid] = item.uuid })
          data.data.libraryQuantity.forEach(library => { this.quantityToAdd[library.library_uuid] = library.quantity })
          console.log('len', Object.keys(this.librariesToAdd).length)
          this.pkg.total_library = Object.keys(this.librariesToAdd).length
        },
        err => console.error(err),
      )
    },
    async listLibrary(params = {}) {
      this.isLoadingLibrary = true
      await this.$services.ApiServices.list(this.serviceLibrary, {
        ...params,
        per_page: 100,
        page: 1,
      }).then(
        async ({ data }) => {
          this.libraries = data.data.map(item => ({
            ...item,
            typeLibrary: {
              audio: item.audio,
              video: item.video,
              ebook: item.ebook,
            },
          }))

          this.totalItemsLib = data.meta.total
          this.totalPagesLib = data.meta.per_page
          this.totalBook = data.meta.total
        },
        err => console.error(err),
      )
      this.isLoadingLibrary = false
    },

    async changeLibraryList(uuid) {
      this.isLoadingLibrary = true
      this.categoryUuid = uuid
      this.pageLib = 1
      this.pageLibCategory = 1
      this.refreshInfiniteLoading()
      console.log(this.categoryUuid)
      await this.$services.ApiServices.get(this.serviceLibraryCategory, uuid, { per_page: (this.isSelectAll ? 'all' : 100), page: 1 }).then(
        ({ data }) => {
          const librariesData = data.data
          librariesData.forEach(item => {
            item.selected = this.librariesToAdd.includes(item.uuid)
            console.log('item', item)
            if (!item.selected && this.isSelectAll) this.$set(this.librariesToAdd, item.uuid, item.uuid)
            if (!item.selected && this.isSelectAll) this.$set(this.quantityToAdd, item.uuid, 5)
            // data.data.library.forEach(item => { this.librariesToAdd[item.uuid] = item.uuid })
            // data.data.libraryQuantity.forEach(library => { this.quantityToAdd[library.library_uuid] = library.quantity })
          })
          this.libraries = data.data.map(item => ({
            ...item,
            typeLibrary: {
              audio: item.audio,
              video: item.video,
              ebook: item.ebook,
            },
          }))
          this.totalItemsLib = data.meta.total
          this.totalPagesLib = data.meta.per_page
          console.log(this.librariesToAdd)
          console.log(this.quantityToAdd)
        },
        err => console.error(err),
      )
      this.isLoadingLibrary = false
    },
    async clickAll() {
      this.pageLib = 1
      this.pageLibCategory = 1
      this.categoryUuid = null
      this.refreshInfiniteLoading()
      await this.listLibrary()
    },
    getBadgeType(type) {
      if (type === 'ebook') {
        return 'v-chip-light-bg warning--text'
      }
      if (type === 'audio') {
        return 'v-chip-light-bg success--text'
      }

      return 'v-chip-light-bg primary--text'
    },
    checkButton() {
      // this.quantity = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      if (!this.isSelectAll) {
        // this.librariesToAdd = []
        // this.quantityToAdd = []
        // this.total_book = this.librariesToAdd.length
      }
      console.log(this.librariesToAdd)
      console.log(this.quantityToAdd)
    },
    async selectAll() {
      if (this.allSelected) {
        const selected = this.libraries.map(library => library.uuid)
        this.librariesToAdd = selected
        // this.quantityToAdd.push(5)
        // eslint-disable-next-line no-plusplus
        // for (let i = 1; i < this.librariesToAdd.length; i++) {
        //   this.quantityToAdd.push(5)
        // }
        console.log(this.librariesToAdd)
        console.log(this.quantityToAdd)
      } else {
        this.librariesToAdd = []
        this.quantityToAdd = []
        console.log(this.librariesToAdd)
        console.log(this.quantityToAdd)
      }
    },
    handleCheckboxChange(uuid, selected) {
      // console.log('ini qtyy', this.quantityToAdd)
      // console.log('c', Object.keys(this.librariesToAdd).includes(uuid))
      if (!this.quantityToAdd[uuid]) {
        // ^ check if librariesToAdd is first time checked, then set default quantity to 5
        this.quantityToAdd[uuid] = 5
      } else if (Object.keys(this.librariesToAdd).includes(uuid)) {
        // ^ check if librariesToAdd checkbox is being unchecked, then remove library and quantity
        this.$delete(this.librariesToAdd, uuid)
        this.$delete(this.quantityToAdd, uuid)
      }
      // this.quantityToAdd.push(count)
      console.log(this.librariesToAdd)
      // cosnsole.log(this.quantityToAdd)
    },
    handleAcChange(data, selected) {
      this.quantityToAdd.push(data)
      console.log(this.quantityToAdd)
    },
    handleSelectChange(data, selected) {
      // this.quantityToAdd.push(event)
      console.log(this.librariesToAdd)
      console.log(this.quantityToAdd)
      // console.log(data)
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style scoped>
.packet-header .header-left ul {
  padding-left: 0;
}
.disable {
  /* cursor: not-allowed; */
  pointer-events: none;
}

.disable-wrap {
  cursor: not-allowed;
}
.packet-header .header-left ul li,
.packet-header .header-right ul li {
  list-style: none;
  margin-bottom: 1rem;
}
.v-application::v-deep a {
  color: #746d7d;
  text-decoration: none;
}

.is-colored.v-input,
.is-colored.v-input--is-disabled {
  color: #AC268F !important;
}
</style>
