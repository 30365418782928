<template>
  <div>
    <v-card>
      <DataTablePagination
        :headers="headers"
        :search.sync="search"
        :items="packages"
        :total-items="totalItems"
        :total-pages.sync="totalPages"
        title="Paket"
        subtitle="Halaman untuk menambahkan paket buku"
        add-btn="Tambah data"
        :is-loading="isLoadingData"
        @add-item="showFormAdd"
        @edit-item="showFormEdit"
        @delete-item="confirmDestroy"
        @change-page="paginationHandler"
        @live-search="searchHandler"
      >
      </DataTablePagination>

      <v-dialog
        v-model="formDialog"
        persistent
        width="1080"
      >
        <v-card>
          <v-card-title class="headline justify-center">
            {{ modalTitle }}
          </v-card-title>
          <v-card-text class="pl-0 pr-0">
            <v-tabs
              v-model="tab"
              show-arrows
              fixed-tabs
              class="justify-space-between"
            >
              <v-tab
                v-for="(tabItem, index) in tabs"
                :key="tabItem.icon"
                :disabled="!unlockTab && tabItem.title === 'Pilih Paket'"
                class="d-flex "
                @click="reRenderComponent(index)"
              >
                <v-icon
                  size="20"
                  class="me-3"
                >
                  {{ tabItem.icon }}
                </v-icon>
                <span>{{ tabItem.title }}</span>
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="tab"
              class="px-5"
            >
              <v-tab-item>
                <packet-form
                  :key="componentKey"
                  :dialog="dialog"
                  :package-uuid="packageUuid"
                  @next-tab="nextTabHandler"
                  @close-dialog="handleCloseDialog"
                  @component-valid="disabledComponent($event)"
                ></packet-form>
              </v-tab-item>
              <v-tab-item>
                <packet-option
                  :key="componentKey1"
                  :dialog="dialog"
                  :pkg="packet"
                  @prev-tab="prevTabHandler"
                  @close-dialog="handleCloseDialog"
                  @success-save="successSave"
                ></packet-option>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-dialog>

      <ConfirmDialog
        :visible="confirmDialog"
        persistent
        :width="600"
        :is-loading-button="isLoadingButton"
        header="Hapus Paket"
        @close-button="confirmDialog = false"
        @destroy-button="destroy"
      >
      </ConfirmDialog>
    </v-card>
  </div>
</template>

<script>
import toCurrencyFormat from '@/utils/currency/toCurrencyFormat'
import ConfirmDialog from '../components/ConfirmDialog.vue'
import DataTablePagination from '../components/DataTablePagination.vue'
import PacketForm from './PacketForm.vue'
import PacketOption from './PacketOption.vue'

export default {
  components: {
    ConfirmDialog,
    DataTablePagination,
    PacketForm,
    PacketOption,
  },
  data() {
    return {
      componentKey: 0,
      componentKey1: 0,
      search: '',
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      formDialog: false,
      modalDialog: false,
      confirmDialog: false,
      packageUuid: null,
      page: 1,
      totalItems: 0,
      totalPages: 0,
      dialog: '',
      headers: [
        { text: 'Nama Paket', value: 'name' },
        { text: 'Harga', value: 'price' },
        { text: 'Total Buku', value: 'total_library' },
        { text: 'Deskripsi', value: 'description' },
        { text: 'Total E-book', value: 'total_ebook' },
        { text: 'Total Video Book', value: 'total_video_book' },
        { text: 'Total Audio Book', value: 'total_audio_book' },
        { text: 'Actions', value: 'actions' },
      ],
      packages: [],
      packet: {
        uuid: '',
        name: '',
        price: '',
        total_book: '',
        description: '',
      },
      tab: '',
      tabs: [{ title: 'Informasi Paket' }, { title: 'Pilih Paket' }],
      filterQuery: {
        search: '',
      },
      selectedPacket: {},
      servicePackage: 'package',
      componentValid: false,
      unlockTab: false,
      modalTitle: '',
    }
  },
  computed: {},
  watch: {
    page: {
      handler() {
        this.listPackage(this.filterQuery)
      },
    },
  },
  created() {
    this.listPackage()
  },
  mounted() {},
  methods: {
    async listPackage(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.servicePackage, { ...params, page: this.page }).then(
        ({ data }) => {
          this.packages = data.data.map((pkg, index) => ({
            ...pkg,
            index: index + 1,
          }))
          this.totalPages = data.meta.last_page
          this.totalItems = data.meta.total
          console.log(data.data)
          // eslint-disable-next-line no-undef
          console.log(`${toCurrencyFormat(this.packages[0].price)}`)
        },
        err => console.error(err),
      )
      this.isLoadingData = false
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.packet.uuid = uuid
    },

    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.servicePackage, this.packet.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'error',
          })
          this.listPackage(this.filterQuery)
        },
        err => console.error(err),
      )
      this.isLoadingButton = false
      this.confirmDialog = false
    },
    reRenderComponent(index) {
      if (index !== this.tab) this.unlockTab = !this.unlockTab

      this.componentKey1 += 1
    },
    disabledComponent(formValid) {
      this.componentValid = formValid
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    showFormAdd() {
      this.dialog = 'add'
      this.modalTitle = 'Tambah Paket Buku'
      this.formDialog = true
    },
    showFormEdit(uuid) {
      this.dialog = 'edit'
      this.modalTitle = 'Edit Paket Buku'
      this.packageUuid = uuid
      this.formDialog = true
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    nextTabHandler(data) {
      this.unlockTab = true
      this.componentKey1 += 1
      this.packet = data
      this.tab += 1
    },
    prevTabHandler() {
      this.unlockTab = !this.unlockTab
      this.tab -= 1
    },
    handleCloseDialog() {
      this.packageUuid = null
      this.formDialog = false
      this.dialog = ''
      // this.listPackage(this.filterQuery)
      this.tab = ''
    },
    successSave() {
      this.listPackage(this.filterQuery)
    },
    searchHandler(data) {
      this.filterQuery.search = data
      this.page = 1
      this.listPackage(this.filterQuery)
    },
  },
}
</script>

<style>
</style>
